import "./src/styles/global.css";

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { hash } = location;

  if (hash) {
    const scrollToHash = () => {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    };

    // Use a small delay to allow the page to render
    setTimeout(scrollToHash, 100);
    return false;
  }

  return true;
};
